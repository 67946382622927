import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { ApiService } from './core/services/api.services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false},     
    },
    DatePipe,
  ],
})
export class AppComponent {
  title = 'APP';

  basicInfoForm: FormGroup;

  otherDetailsForm: FormGroup;

  documentsForm: FormGroup;

  uploader: FileUploader;

  hasBaseDropZoneOver: any = {
    BRP: false,
    BRPBack: false,
    Ni: false,
    passport: false,
    passportBack: false,
    CV: false,
  };

  isSubbmitted = false;

  isLoaderActive = false;

  passPortSingleDoc = false;

  BRPSingleDoc = false;

  constructor(private _formBuilder: FormBuilder, private apiService: ApiService, private datePipe: DatePipe) {
    this.basicInfoForm = this._formBuilder.group({
      FullName: ['', Validators.required],
      Email: ['', Validators.required],
      Mobile: ['', Validators.required],
    });
    this.otherDetailsForm = this._formBuilder.group({
      LandLine: [''],
      DOB: [''],
      LanguageKnowledge: [''],
      EducationDetail: [''],
      JobPreference: [''],

      LookingFor:[''],
      HNo: [''],
      StreetName: [''],
      PostCode: [''],
      City: [''],
      County: [''],
      Country: [''],
    });
    // const fileuploader = new FileUploader({
    //   disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
    //   formatDataFunctionIsAsync: true,
    // });
    this.documentsForm = this._formBuilder.group({
      Passport: ['', Validators.required],
      PassportName: [''],
      PassportBack: ['', Validators.required],
      PassportBackName: [''],

      Ni: [''],
      NiName: [''],

      BRP: [''],
      BRPName: [''],
      BRPBack: [''],
      BRPBackName: [''],

      PassportNumber: ['', Validators.required],
      NINumber: [''],
      BRPNumber: [''],

      CV: [''],
      CVName: [''],
    });


    this.uploader = new FileUploader({
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
    });

    // Home Land Line/Mobile Number:
    //                     Permanent Address
    //                     Current Address
    //                     Date of Birth
    //                     Language Knowledge
    //                     Education Detail
  }

  ngOnInit() { }

  public fileOverBase(e:any, control: any):void {
    this.hasBaseDropZoneOver[control] = e;
  }

  onSendMain() {

    if (this.documentsForm.valid && this.basicInfoForm.valid) {
      this.isLoaderActive = true;
  
      const passportFile =  this.documentsForm.get("Passport")?.value;
      const PassportBackFile =  this.documentsForm.get("PassportBack")?.value;
  
      const NiFile =  this.documentsForm.get("Ni")?.value;
  
      const BRPFile =  this.documentsForm.get("BRP")?.value;
      const BRPBackFile =  this.documentsForm.get("BRPBack")?.value;

      const CVFile =  this.documentsForm.get("CV")?.value;
  
      const payLoad = {
        MessageFrom: this.basicInfoForm.get("FullName")?.value,
        Email: this.basicInfoForm.get("Email")?.value,
        Mobile: this.basicInfoForm.get("Mobile")?.value,
  
        LandLine: this.otherDetailsForm.get("LandLine")?.value,
        DOB: this.otherDetailsForm.get("DOB")?.value ? this.datePipe.transform(this.otherDetailsForm.get("DOB")?.value) : '',
        LanguageKnowledge: this.otherDetailsForm.get("LanguageKnowledge")?.value,
        // PermanentAddress: this.otherDetailsForm.get("PermanentAddress")?.value,
        // CurrentAddress: this.otherDetailsForm.get("CurrentAddress")?.value,
        EducationDetail: this.otherDetailsForm.get("EducationDetail")?.value,
        LookingFor: this.otherDetailsForm.get("LookingFor")?.value,
  
        HNo: this.otherDetailsForm.get("HNo")?.value,
        StreetName: this.otherDetailsForm.get("StreetName")?.value,
        PostCode: this.otherDetailsForm.get("PostCode")?.value,
        City: this.otherDetailsForm.get("City")?.value,
        County: this.otherDetailsForm.get("County")?.value,
        Country: this.otherDetailsForm.get("Country")?.value,
        JobPreference: this.otherDetailsForm.get("JobPreference")?.value,
        
  
        passportFile : passportFile,
        PassportBackFile: PassportBackFile,
        PassportNumber: this.documentsForm.get("PassportNumber")?.value,
        // passport: 'passportName',
  
        Ni: NiFile,
        NINumber: this.documentsForm.get("NINumber")?.value,
        // NiName: 'NiName',
  
        BRPFile: BRPFile,
        BRPBackFile: BRPBackFile,
        BRPNumber: this.documentsForm.get("BRPNumber")?.value,

        CVFile: CVFile,
        
      }
     
      this.apiService.post('sendMail', payLoad).subscribe((result) => {
        console.log(result);
        this.isSubbmitted = true;
        this.isLoaderActive = false;
        this.uploader.clearQueue();
      })
    } else {
      window.alert('Please Enter All Mandatory Fields!');
    }
  }

  scroll(el: HTMLElement) {
      el.scrollIntoView();
  }

  public handleUpload(event: any, from: string, control: string) {
    // this.onUpload();
    // this.uploader.onCompleteAll = () => {
    //     this.appBroadcastService.onUpdateAppSpinnerPrompt(informationMessage.Empty);
    // };
    let target;
    let fileName: any;
    let fileSize;
    let files;
    if (from === "select") {
        target = event.target || event.srcElement;
        fileName = target.files[0].name;
        fileSize = target.files[0].size;
        files = target.files;
        // this.uploader.addToQueue(files);
    } else {
        fileName = event[0].name;
        fileSize = event[0].size;
        files = event;
    }

    const myReader:FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.documentsForm.get(control)?.setValue(myReader.result);
      this.documentsForm.get(control)?.markAsDirty();
      this.documentsForm.get(control+'Name')?.setValue(fileName);
      this.documentsForm.get(control+'Name')?.markAsDirty();
    }
    myReader.readAsDataURL(files[0]);


  }

  onCheckBoxChange(control: any) {
    if (control === 'Passport') {
      this.passPortSingleDoc ? this.documentsForm.get('PassportBack')?.setValidators(Validators.nullValidator) : this.documentsForm.get('PassportBack')?.setValidators(Validators.required);
      this.documentsForm.get('PassportBack')?.updateValueAndValidity();
    }
  }
}
