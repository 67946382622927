<div class="container-fluid p-0">
    <nav class="navbar navbar-expand-lg navbar-dark bg-transparent zi-9">
        <div class="container-xl">
            <a class="navbar-brand">
                <div class="d-flex">
                    <!-- <div>
                        <img src="././assets/images/logo.jpg" style="height: 60px;border-radius: 5px;">
                    </div> -->
                    <div>
                        <h1 class="logo">
                            
                            heyQA
                        </h1>
                        <h4 class="tagline">
                            Let's Make Quality the Culture in SDLC
                        </h4>
                    </div>
                </div>


            </a>
            <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll"
                aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button> -->
            <button class="enroll-button navbar-toggler" (click)="scroll(target)">Contact US</button>
            <div class="collapse navbar-collapse" id="navbarScroll">
                <ul class="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll" style="--bs-scroll-height: 100px;">
                    <li class="nav-item">
                        <a class="nav-link active" aria-current="page"  (click)="scroll(about)">About Us</a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" href="about-us.html#CareerContainer" tabindex="-1"
                            aria-disabled="true">Careers</a>
                    </li> -->
                </ul>

                <button class="enroll-button" (click)="scroll(target)">Contact US</button>
                <!-- <form class="d-flex">
  <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
  <button class="btn btn-outline-success" type="submit">Search</button>
</form> -->
            </div>
        </div>
    </nav>
    <!-- Slider Content-->
    <div id="carouselExampleDark" class="carousel carousel-light slide" data-bs-ride="carousel">
        <!-- <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active"
                aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1"
                aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2"
                aria-label="Slide 3"></button>
        </div> -->
        <div class="carousel-inner">
            <!-- <div class="carousel-item active" data-bs-interval="10000">
              <img src="././assets/images/home/home-banner-1.jpg" class="d-block w-100" alt="...">
              <div class="carousel-caption d-none d-md-block">
                  <h5>First slide label</h5>
                  <p>Some representative placeholder content for the first slide.</p>
              </div>
          </div> -->
            <div class="carousel-item active" data-bs-interval="2000">
                <img src="././assets/images/home/home-banner-2.jpg" class="d-block w-100" alt="...">
                <!-- <div class="carousel-caption d-none d-md-block">
                    <h5>Second slide label</h5>
                    <p>Some representative placeholder content for the second slide.</p>
                </div> -->
            </div>
            <!-- <div class="carousel-item">
                <img src="././assets/images/home/home-banner-3.jpg" class="d-block w-100" alt="...">
                <div class="carousel-caption d-none d-md-block">
                    <h5>Third slide label</h5>
                    <p>Some representative placeholder content for the third slide.</p>
                </div>
            </div> -->
        </div>
        <!-- <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button> -->
    </div>
    <!-- Why we choose Container start here -->
    <div class="why-choose-container" #about>
        <div class="container-xl">
            <div class="row g-3">
                <div class="col col-sm-12">
                    <h1 class="h1-txt">
                        <p>About <span class="gyg-text">heyQA</span> </p>
                    </h1>
                    <!-- <div class="d-block d-sm-none mx-3">
                        <img src="./assets/images/home/why-syntel.jpg" class="img-fluid">
                    </div> -->
                    <p class="p-text">Welcome to our QA consultancy services! Together, let's infuse your SDLC with a relentless commitment to quality. With over a decade of industry expertise, we're here to revolutionize your testing processes. Our specialties include:</p>
                    

                    <div class="row">
                        <div class="col-md-6 col-sm-6">
                            <div class="value-block">Manual Testing</div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <div class="value-block">Automation Testing</div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="value-block">Devops</div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="value-block">SRE</div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="value-block">Services</div>
                        </div>
                    </div>
                    




                    

                    

                        

                       

                        <!-- <p class="p-text">
                            Don’t take our word for it…
                        </p> -->

                        
                        <!-- <h2>
                            Testimonials from clients:
                        </h2>
                        
                        <p class="p-text">
                            “The Ideal HIRING team developed a recruitment process for our temporary staff that has made a positive contribution to our business” – Managing Director, Automotive.

                        </p>

                        <p class="p-text">
                            “Ideal HIRING provide an excellent professional, personable service. A pleasure to do business with” – HR Manager, Manufacturing.

                        </p>
                        <p class="p-text">
                            “It was a quick turnaround and very smooth professional service” – NHS Recruitment Manager.

                        </p>
                        <p class="p-text">
                            “The rates are very reasonable compared to other technical recruiters in the area, and they provide excellent quality candidates” – HR Director, Aerospace.
     
                        </p> -->



               <!-- <a class="read-more mb-4 d-inline-block">Read More...</a> -->
                </div>
            </div>
        </div>
    </div>

    <!-- Careers Container -->
    <div class="career-container" #target>
        <div class="container-xl">
            <div class="row g-0">
                <div class="col-12">
                    <h1 class="h1-txt pb-4">
                        <p> <span class="spl-text">Contact US</span> </p>
                        <p class="p-text">Ready to take your testing to the next level? Don't wait! Reach out now for a consultation that will transform your approach to QA</p>
                    </h1>

                    <div *ngIf="isSubbmitted">
                        <h2 class="h2-txt mb-4 mt-4">Thank you for contacting us. we will resopond back to you within 24 hours.

                            <a class="linktext" (click)="isSubbmitted = !isSubbmitted">Back</a>
                        </h2>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="values-container">
        <div class="container-xl">
            <h1 class="h1-txt">
                Services
            </h1>
            <p class="p-text">Our mission is simple: to streamline and elevate your testing efforts. We offer a dynamic range of services, including::
            </p>

            <div class="row p-text left-allign">
                <div class="col-md-6">
                    <ul>
                        <li>Manual Testing</li>
                        <li>Test Automation Strategy</li>
                        <li>API Test Automation Strategy</li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <ul>
                        <li>Framework Design and Implementation</li>
                        <li>Training and Mentoring</li>
                        <li>Test Infrastructure Setup</li>
                    </ul>
                </div>
            </div>

            
        </div>

    </div>

    <!-- <div class="career-container" #target>
        <div class="container-xl">
            <div class="row g-0">
                <div class="col-12">
                    <h1 class="h1-txt pb-4">
                        <p> <span class="spl-text">Clients</span> </p>
                        <p class="p-text">We've proudly collaborated with industry leaders such as::</p>
                    </h1>

                    <ul class="p-text left-allign">
                        <li>OCBC Bank
                            </li>
                        <li>OpenText Technologies
                            </li>
                        <li>Cigniti Technologies</li>
                    </ul>

                </div>
            </div>
        </div>
    </div> -->

    <!-- Footer Container-->
    <div class="footer-container">
        <div class="container-xl">
            <div class="row g-3">
                <div class="col-12 col-md-3 col-sm-12">
                    <h4 class="footer-h4">Site Map</h4>
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <ul>
                                <li><a (click)="scroll(about)">About Us</a></li>
                                <li><a  (click)="scroll(target)">Contact Us</a></li>
                                <!-- <li><a href="">Contact Us</a></li> -->
                                <!-- <li><a href="">Privacy Policy</a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12 col-sm-3">2</div> -->
                <div class="col-12 col-md-3 col-sm-6">
                    <h4 class="footer-h4">We are on Social</h4>
                    <ul>
                        <li>
                            <a href="#" target="_blank">
                                <!-- <i class="fab fa-facebook-square"></i> -->
                                Facebook
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                Linkedin
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-md-6 col-sm-6">
                    <h4 class="footer-h4">Contact Us</h4>
                    <!-- <div class="row">
                        <div class="col-md-4">
                            <ul>
                                <li class="li"><a>20-22 Wenlock Road</a></li>
                                <li class="li"><a>London</a></li>
                                <li class="li"><a>N1 7GU</a></li>
                            </ul>
                        </div>
                        <div class="col-md-8">
                            <ul>
                                <li class="li spl">Telephone: <br> <a href="tel:0808 296 8241">0808 296 8241,</a> <a
                                        href="tel:0808 150 3992">0808 150 3992.</a></li>
                                <li class="li spl">Mobile: <br> <a href="tel:+447341648293">+447341648293,</a> <a
                                        href="tel:+447586704774">+447586704774.</a></li>
                            </ul>
                        </div>
                    </div> -->

                </div>
            </div>
        </div>
    </div>
</div>

<div class="loader" *ngIf="isLoaderActive">
    <img src="../assets/loader.gif" alt="">
</div>